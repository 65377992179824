.gl-accounts {
  .empty-table {
    display: flex;
    height: 50%;
  }
  height: 100%;
  .table-wrapper {
    height: 100%;
    table {
      th {
        min-width: 140px;
      }
    }
  }
}

