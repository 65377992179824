@import "../assets/styles/variables";
.toast-container {
  .toast {
    &.success {
      background: $success;
    }
    &.error {
      background: $error;
    }
    &.info {
      background: $info;
    }
  }
}

