@import "../../../assets/styles/variables";
form {
  .period-controls {
    position: relative;
    bottom: 0;
    padding-top: 100px;
    .control {
      padding: 10px 0;
      display: flex;
    }
  }
  padding: 20px;
  color: $form_color;
  .company-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .company-id {
      display: flex;
      align-items: center;
    }
  }
  .right {
    text-align: right;
  }
  .fiscal-period {
    display: flex;
  }
}
