@import "../../../assets/styles/variables";

.table-wrapper {
  overflow: auto;
  height: 100%;
  padding: 0 15px;
  .table {
    table-layout: fixed;
    word-wrap: break-word;
    //filter: drop-shadow(1px 0px 8px rgba(0, 0, 0, 0.25));
    td.show-shadow, th.show-shadow {
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        width: 1px;
        top: 0;
        right: 0;
        height: 100%;
        background: #c6c6c6;
        filter: drop-shadow(1px 0px 2px rgba(0, 0, 0, 0.6))
      }
    }
    .header-row {
      &:hover {
        background: #fff;
      }
      td {
        width: fit-content;
        text-align: left;
        position: sticky;
        left: 0;
        font-weight: bold;
        &:hover {
          background: #fff;
        }
      }
    }
    font-size: 14px;
    .search {
      td {
        background-color: #fff;
        position: sticky;
        top: 20px;
        &.freeze{
          z-index: 2;
        }
        input {
          width: 100%;
          border-radius: 6px;
          border: 1px solid #D1D5DB;
        }
      }
    }
    tr.total-data {
      background-color: #F1F4FF;
      font-weight: bold;
    }
    color: $form_color;
    thead {
      th {
        width: fit-content;
        background-color: #f2f2f2;
        position: sticky;
        top: 0;
        z-index: 1;
        text-align: center;
        &.freeze {
          left: 0;
          z-index: 2;
        }
        .header-column {
          display: flex;
          justify-content: center;
          align-items: center;
          &.sort {
            cursor: pointer;
          }
        }
      }
      background: rgba(0, 0, 0, 0.05);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      th:first-child {
        border-top-left-radius: 8px;
      }
      th:last-child {
        border-top-right-radius: 8px;
      }
    }
    tbody {
      .clickable {
        cursor: pointer;
      }
      .active {
        border: 2px solid $primary;
      }
      td {
        width: fit-content;
        &.disabled-click {
          pointer-events: none;
        }
        &.error {
          color: red;

        }
        &.freeze {
          position: sticky;
          left: 0;
          z-index: 1;
          background: inherit;
      }
        border: unset;
        text-align: center;
      }
      tr:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        td:first-child {
          border-bottom-left-radius: 8px;
        }
        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
}
