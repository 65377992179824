.grant-budget {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .new-line {
    display: flex;
    justify-content: end;
    padding-top: 20px;
    padding-right: 20px;
  }
  table {
    thead {
      p {
        margin: 0;
      }
    }
  }
}
