.payroll-register {
  .empty-table {
    height: 50%;
    display: flex;
  }
  height: 100%;
  >div {
    height: 100%;
  }
}
