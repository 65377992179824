.user-form-modal {
  .user-form {
    width: 100%;
  }
}
.header {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}

