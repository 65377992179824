.billing-allocation {
  padding: 30px 10px;
  .methods {
    display: flex;
    flex-direction: column;
    select {
      max-width: 400px;
    }
    .form-check {
      input {
        min-width: 1em;
      }
      display: flex;
      align-items: center;
      .label {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
