html, body {
  height: 100%;
  #root {
    height: 100%;
  }
  .error {
    font-size: 14px;
    color: red;
  }
}
$theme-colors: (
  primary: #1357A6,
  danger: #FF4343,
);
$table-striped-order: even;
@import "node_modules/bootstrap/scss/bootstrap";

@import "./assets/styles/mixins";
@import "./assets/styles/typography";
@import "./assets/styles/variables";
.btn-secondary {
  background-color: #E0E7FF;
}
.nav-link.grant-budget {
  color: #1357A6;
  &.active {
    background-color: #1357A6;
  }
}
.space {
  width: 80px;
}
.form-field {
  .error {
    color: $red
  }
}

