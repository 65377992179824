header.company-header {
  height: 150px;
  padding: 0 10px;
;
  >div {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
}
.content {
  height: calc(100% - 180px);
}
