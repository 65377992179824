@import "../../assets/styles/variables";

.company-id {
  display: flex;
  align-items: center;
  #id {
    color: $white;
    max-width: 120px;
    background: $primary;
  }
}
.company-tabs {
  #companyPartsTabs, #companyTypeTabs {
    button {
      color: grey;
    }
    .active {
      border: none;
      border-bottom: 2px solid;
      color: $primary;
    }
  }
  .tab-content .active {
    border: unset;
  }
}

